import "./App.scss";
import React, { useEffect, useState } from "react";
import { Navigate, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Layout, Breadcrumb, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./components/Sidebar";
// import MyHeader from "./components/Header";
import MyHeader from "./components/MyHeader";
import { setUserPermission, setUserInfo, setAdmins } from "./store/user/user";
import _routers from "./router/router";
import Scrollbars from "react-custom-scrollbars";
import UserApi from "./apis/user/index";
import PrivateRoute from "./components/PrivateRoute";
import { removeToken, setRedirectUrl, setToken } from "./unit/index";
import projectApi from "./apis/project";
import userApi from "./apis/user/index";
import MenuLoadingPage from "./components/MenuLoadingPage";
import { useGetState } from "ahooks";

const { Content } = Layout;

function App() {
  const userRouters = useSelector((state) => state.User.routers);
  const [routerList, setRouterList] = useState([]);
  const [breadcrumbName, setBreadcrumbName] = useState("");
  const [routers, setRouters] = useState([]);
  const [showRouters, setShowRouters] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = useSelector((state) => state.User.userInfo) || {};

  const [personalMenus, setPersonalMenus] = useState(null);
  const [licenseName, setLicenseName] = useState(null);
  const [userMenus, setUserMenus] = useState([]);

  const [pageLoading, setPageLoading] = useState(true);

  const [routeType, setRouteType, getRouteType] = useGetState("");

  const navigate = useNavigate();

  useEffect(() => {
    if(pageLoading){
      return
    }
    getUserInfo();
    getUserPermission();
    getUserMenus();
    getMenuOfPersonal();
    getAdminsFunc();
  }, [pageLoading]);

  useEffect(() => {
    // console.log('=====',location);
    if(routers?.length === 0){
      return
    }
    let route = routers.find((item) => item.menu_url === location.pathname) || null;
    if(!route){
      routers.forEach(_=>{
        if((_.children || [])?.length > 0){
          route = _.children?.find((item) => item.menu_url === location.pathname) || null;
        }
      })
    }
    if(route?.menu_name === '债券后督'){
      setRouteType('债券后督')
    }else if(route?.menu_name === '工具箱'){
      setRouteType('工具箱')
    }else{
      setRouteType('股权尽调')
    }
    setBreadcrumbName(route?.menu_name);
  }, [location,routers]);

  if(pageLoading){
    return <MenuLoadingPage setPageLoading={setPageLoading}></MenuLoadingPage>
  }

  const getUserPermission = async () => {
    let { data } = await projectApi.getUserPermission();
    dispatch(setUserPermission(data));
  };

  const getUserMenus = () => {
    userApi.getMenus().then((res) => {
      if (res.code === 200) {
        console.log(res?.data);
        const menusItem = res?.data?.find((_) => _.menu_code === "CDD_PROD");
        if (!menusItem) {
          // message.error('未匹配到本项目的目录数据!');
          setRouters([]);
          //没订阅过 跳到详情页
          window.location.href = localStorage.getItem("sub_url");
          return;
        }
        let showRouters = [];
        const __routers = menusItem?.children?.map((menu) => {
          if (menu?.children?.length > 0) {
            menu.children = menu?.children?.map((_menu) => {
              const menuItem = _routers?.find(
                (_) => _.menu_name === _menu.menu_name
              );
              if(!_menu.icon){
                _menu.icon = menuItem?.icon;
              }
              
              _menu.element = menuItem?.element;
              if (_menu.menu_url) {
                showRouters.push(_menu);
              }
              return _menu;
            });
          }
          const menuItem = _routers?.find(
            (_) => _.menu_name === menu.menu_name
          );
          if(!menu.icon){
            menu.icon = menuItem?.icon;
          }
          menu.element = menuItem?.element;
          if (menu.menu_url) {
            showRouters.push(menu);
          }
          return menu;
        });

        // TODO: 手动添加债券后督
        // const bondItem = _routers?.find((_) => _.menu_name === "债券后督");
        // bondItem.open_mode = "CURRENT_PAGE";
        // __routers.push(bondItem);
        // if (!showRouters?.some((__) => __?.menu_name === "债券后督")) {
        //   let item1 = _routers?.find((___) => ___?.menu_name === "债券后督");
        //   showRouters.push(item1);
        // }

        setShowRouters(showRouters);
        setRouters(__routers);
        setUserMenus(res?.data);
      } else {
        message.error(res.msg);
        setShowRouters([]);
        setUserMenus([]);
        setRouters([]);
      }
    });
  };

  const getUserInfo = async () => {
    let { data } = await UserApi.getUserInfo();
    if (!data) {
      logout();
      return;
    }
    dispatch(setUserInfo(data));
  };

  const getAdminsFunc = async () => {
    projectApi.getAdmins().then((res) => {
      console.log(res?.data);
      dispatch(setAdmins(res?.data || []));
    });
    //
  };
  //获取菜单
  const getMenuOfPersonal = () => {
    userApi.getMenuOfPersonal().then((res) => {
      console.log(res);
      if (res.code === 200) {
        setPersonalMenus(res.data?.menu_list);
        setLicenseName(res.data?.license_name);
      } else {
        setPersonalMenus([]);
        setLicenseName(null);
        message.error(res.message);
      }
    });
  };

  const logout = () => {
    let redirect = window.location.href // + "/login";
    UserApi.logout(redirect).then((res) => {
      // console.log(res.data.data.logout_url);
      removeToken();
      if (window.location.pathname !== "/login") {
        setRedirectUrl(window.location.href);
      }
      if (res?.data?.status === "redirect") {
        window.location.href = res.data.logout_url;
      }
      if (res.code == "3") {
        window.location.reload();
      }
    });
  };

  //切换企业成功回调
  const switchEnterpriseSuccessFunc = (data) => {
    console.log("switchEnterpriseSuccessFunc", data);
    setToken(data.token);
    navigate("/");
    window.location.reload();
    // setToken(data?.data.token || '');
    // setCompany(data?.data?.company || '');
    // setSuperGroup(data?.data.super_group || false);
    // window.location.reload()
  };

  const authCallBack = (type) => {
    console.log("authCallBack", type);
  };

  const contactBusinessCallBack = () => {
    console.log("联系商务");
    if(localStorage.getItem('deploy') === 'BOX'){
      return
    }
    window.open(localStorage.getItem("sub_url"), "sub_url");
  };

  const menuClickCallBack = (item) => {
    if (item.menu_name === "帮助中心") {
      window.open(item.menu_url + localStorage.getItem("product_name"), item?.menu_name);
      return true;
    }
  };

  return (
    <div className="App">
      <MyHeader
        key={getRouteType()}
        userInfo={userInfo}
        userMenus={userMenus}
        personalMenus={personalMenus}
        logoutBack={logout}
        getUnreadNoticesFunc={projectApi.getUnreadNotices}
        getNoticesFunc={projectApi.getNotices}
        setReadNoticesFunc={projectApi.setReadNotices}
        deleteNoticesFunc={projectApi.deleteNotices}
        getUpdateLogList={projectApi.getUpdateLogList}
        getEnterpriseFunc={projectApi.getEnterprise}
        switchEnterpriseFunc={projectApi.switchEnterprise}
        switchEnterpriseCallBack={switchEnterpriseSuccessFunc}
        licenseName={licenseName}
        projectCode="CDD_PROD"
        authCallBack={authCallBack}
        getAuthTimeFunc={getRouteType() === '股权尽调' ? projectApi.getLicenseValid : (getRouteType() === '债券后督' ? projectApi.getBondLicenseValid : null)}
        getAdminsFunc={projectApi.getAdmins}
        contactBusinessCallBack={contactBusinessCallBack}
        menuClickCallBack={menuClickCallBack}
        editCompName={projectApi.editCompName}
        getLicenseFunc={projectApi.getLicense}
        // showNotice={false}
      ></MyHeader>
      <Layout className="my-layout">
        <Sidebar routerList={routers} userMenus={userMenus}></Sidebar>
        <Layout>
          {/* <MyHeader></MyHeader> */}
          {/* 面包屑 */}
          <div className="breadcrumb">{breadcrumbName}</div>
          <Content className="my-content">
            {/* {element} */}
            <Scrollbars
              className="scrollbars"
              autoHide={true}
              autoHideTimeout={5000}
              style={{ height: "100%", width: "100%" }}
            >
              <div className="contentBox">
                {showRouters.length > 0 ? (
                  <Routes key={location.key}>
                    {showRouters.map((item) => (
                      <Route
                        path={item.menu_url}
                        key={item.menu_url}
                        breadcrumbName={item.menu_name}
                        element={
                          <PrivateRoute menu_name={item.menu_name}>
                            {item.element}
                          </PrivateRoute>
                        }
                      />
                    ))}
                    <Route
                      path="/*"
                      element={<Navigate to={showRouters[0]?.menu_url} />}
                    />
                  </Routes>
                ) : null}
              </div>
            </Scrollbars>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
